export default {
  name: 'Bloom',
  logo: 'login/bloom/logo-primary.svg',
  loginImage: 'login/bloom/login-image.svg',

  // translations keys
  t: {
    // Login
    onboarding2_welcome: 'onboardingbloom_welcome',
    // EligibilityQuestions
    onboarding2_lets_confirm_sword_title: 'onboardingbloom_lets_confirm_bloom_title',
    // LegalFields:
    'onboarding_legaldocs_UK-US-Canada_1': 'onboardingbloom_legaldocs_UK-US-Canada_1',
    onboarding_legaldocs_US_HIPAA: 'onboardingbloom_legaldocs_US_HIPAA',
    'onboarding_legaldocs_US-Canada_3': 'onboardingbloom_irb_consent',
    onboarding_legaldocs_US_2: 'onboardingbloom_legaldocs_US_2',
    // LockedLogin:
    onboarding2_blocked_account: 'onboardingbloom_blocked_account',
    onboarding2_error_login_attempts: 'onboardingbloom_error_login_attempts',
    // PasswordRecoverySuccess:
    onboarding2_account_forgot_password_check_email_title: 'onboardingbloom_check_your_inbox',
    onboarding2_account_forgot_password_check_email_subtitle: 'onboardingbloom_we_just_emailed_you',
    onboarding2_account_forgot_password_check_email_tip: 'onboardingbloom_didnt_receive_an_email',
    'button.GoBackToLogin': 'onboardingbloom_back_to_login',
    onboarding2_link_expitred_title: 'account.title_delete_link_expired',
    'account.delete_account_descrtiption': 'onboardingbloom_about_delete_account',
    'account.question_reason_for_leaving': 'onboardingbloom_how_can_we_improve',
    'account.title_delete_check_email': 'onboardingbloom_confirming_delete_account',
    // SubmissionSummary.vue:
    eco_resume_email_pt: 'eco_resume_email_pt_bloom',
    onboarding2_second_subtitle_summary: 'onboarding2_next_steps',
    onboarding2_summary_pt_section_title: 'onboardingbloom_subtitles_pt_looking_video_call',
    eco_resume_kit: 'onboardingbloom_subtitles_kit_sent_address',
    'onboarding_kit.block_title1': 'onboardingbloom_summary_kit_details_title',
    'onboarding_kit.block_paragraph1': 'onboardingbloom_summary_kit_arrive',
    'onboarding_resume_keep.box': 'onboardingblooom_summary_kit_includes_title',
    'onboarding_resume_keep.box.message': 'onboardingbloom_kit_includes',
    // DemographicsStep.vue:
    onboarding2_titles_demographics: 'onboardingbloom_about_you_title',
    onboarding2_subtitles_demographics: 'onboardingbloom_member_matched_ph',
    // ConditionSelectionStep.vue:
    onboarding2_titles_condition: 'onboardingbloom_title_what_brings_you',
    onboarding2_subtitles_condition: 'onboardingbloom_condition_select_area',
    eco_area_concern_subtitle: 'onboardingbloom_condition_select_area',
    onboarding_message_thank_you: 'onboardingbloom_message_thank_you',
    onboarding_message_availity_label: 'onboardingbloom_your_information_not_appearing',
    // CallPreferences
    onboarding_call_selection_title: 'onboarding_video_call',
    onboarding_schedule_option_subtitle: 'onboarding_bloom_video_call_subtitle',
    onboarding_skip_the_call: 'onboarding_skip_call_title_bloom',
    onboarding_skip_the_call_subtitle: 'onboarding_skip_call_subtitle_bloom',
    onboarding_video_call: 'onboarding_video_call_title_bloom',
    onboarding_video_call_subtitle: 'onboarding_video_call_subtitle_bloom',
    onboarding_phone_call: 'onboarding_phone_call_title_bloom',
    onboarding_phone_call_subtitle: 'onboarding_phone_call_subtitle_bloom',

    // PTSelectionStep:
    /* static */
    onboarding2_titles_choose_pt: 'onboardingbloom_choose_pelvic_health_specialist',
    'onboarding_choose.PT_subtitle': 'onboardingbloom_all_phs_guive_guidance',
    /* dynamic */
    onboarding2_stepper_choose_pt: 'onboardingbloom_your_pelvic_health_specialist',
    onboarding3_titles_choose_pt: 'onboarding3_titles_choose_pt_bloom',
    onboarding3_subtitles_choose_pt: 'onboarding3_subtitles_choose_pt_bloom',
    onboarding3_titles_choose_pt_one_option: 'onboarding3_titles_choose_pt_one_option_bloom',
    onboarding2_schedule_loading_message: 'onboardingbloom_loader_finding_best_pelvic_specialist',
    // AddressStep.vue:
    onboarding2_titles_address: 'onboardingbloom_title_ready_to_send',
    onboarding2_subtitles_address: 'onboardingbloom_your_kit_includes_bloom_trainer',
    legal_link_irb_consent: 'legal_link_irb_consent_bloom',
    // ScheduleCallStep.vue:
    onboarding2_stepper_video_call: 'onboardingbloom_titles_what_day_time_video_call',
    onboarding_message_checking_pt_availability: 'onboardingbloom_message_checking_pt_availability',
    onboarding2_pts_loading_message: 'onboardingbloom_pts_loading_message',
    onboarding2_titles_choose_pt_one_option: 'onboardingbloom_meet_your_new_pelvic_health_specialist',
    onboarding_instant_title: 'onboarding_instant_title_bloom',
    // Eligibility
    onboarding_email_repeated: 'onboardingbloom_modal_email_already_taken_content',
    onboarding2_age_guard_13: 'onboarding2_age_guard_13',
    onboarding_minor_enrollment_block_feedback_message:
      'onboarding_minor_enrollment_block_feedback_message',
    onboarding_message_cap_limit: 'onboarding_message_cap_limit',

    onboarding_eligibility_failed2_title: 'onboarding_eligibility_failed2_title',
    onboarding_eligibility_failed2_body: 'onboarding_eligibility_failed2_body',

    onboarding2_title_message_unable_to_enroll: 'onboardingbloom_not_able_to_enroll',
    onboarding2_subtitle_unable_to_recover: 'onboardingbloom_your_information_not_appearing',
    onboarding_eligibility_failed1_body: 'onboardingbloom_eligibility_failed1_body',

    // PAIN AREAS
    eco_area_concern_title_dpt: 'onboarding_skip_call_symptoms_question',
    eco_area_concern_subtitle_dpt: 'onboarding_select_all_that_apply',

    // ProgramSummary
    onboarding_journey1_dpt_step1: 'onboardingbloom_journey1_dpt_step1',
    onboarding_journey1_dpt_step2: 'onboardingbloom_journey1_dpt_step2',
    onboarding_journey1_dpt_step3: 'onboardingbloom_journey1_dpt_step3',

    // RegisterAccountForm
    onboarding2_phone_number_more_info: 'onboardingbloom_phone_number_more_info',

    // ScheduleCallForm
    onboarding_question_keep_current_therapist: 'onboardingbloom_question_keep_current_therapist',
    onboarding_keep_current_therapist_hint: 'onboardingbloom_keep_current_therapist_hint',
    // Feedbacks:
    onboarding_no_pts_available: 'onboardingbloom_no_phs_available',
    onboarding2_message_appointment_rescheduled: 'onboardingbloom_message_appointment_rescheduled',
    onboarding2_message_unable_to_reschedule: 'onboardingbloom_message_unable_to_reschedule',

    // Coverage Info
    onboarding_answer_accessing_employer: 'onboardingbloom_answer_accessing_employer',
    onboarding_answer_accessing_dependent: 'onboardingbloom_answer_accessing_dependent',
    // Summary
    onboarding2_another_pt_warning: 'onboarding_summary_another_phs_bloom',
    eco_research_consent: 'onboardingbloom_irb_consent',
    eco_download_app_subtitle: 'onboarding_bloom_download_the_app_section',
  },
};
