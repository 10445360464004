import { tools } from '@sword-health/ui-core';

import dayjs from '@/plugins/dayjs-config';
import i18nConfig from '@/plugins/i18n-config';
import { STORAGE_KEYS } from '@/scripts/constants';
import { DEFAULT_LANGUAGE, AVAILABLE_LANGUAGES } from '@/scripts/constants/languages';
import {
  i18nPrivateTypes as types,
  memberStoreTypes,
  clientStoreTypes,
} from '@/store/types';

// INITIALIZATION
const i18nStorage = new tools.StorageInterface(localStorage);
const getBrowserLanguage = () => {
  const browserLocale = navigator?.language ?? '';
  // if language is en-GB or pt-PT this remove the locale, and only returns the language.
  // TODO: improve this so it can also be compatible with locale.
  return browserLocale.split('-')[0];
};

const getLocalStorageLanguage = () => i18nStorage.read(STORAGE_KEYS.SELECTED_LANGUAGE);

// STATE
const cleanState = {
  language: DEFAULT_LANGUAGE,
  languageFromUserSelection: '',
  languageFromQueryParam: '',
  availableLanguages: typeof AVAILABLE_LANGUAGES === 'string' ? AVAILABLE_LANGUAGES.split(',') : AVAILABLE_LANGUAGES,
};

const defaultState = () => ({
  ...cleanState,
});

// GETTERS
const _getters = {
  [types.getters.GET_LANGUAGE]: state => state.language,
  [types.getters.GET_AVAILABLE_LANGUAGES]: state => state.availableLanguages || [],
  [types.getters.GET_LANGUAGE_FROM_QUERY_PARAM]: state => {
    const queryLanguage = state.languageFromQueryParam;
    return state.availableLanguages.includes(queryLanguage) ? queryLanguage : '';
  },
  [types.getters.GET_LANGUAGE_FROM_USER_SELECTION]: state => {
    const userLanguage = state.languageFromUserSelection;
    return state.availableLanguages.includes(userLanguage) ? userLanguage : '';
  },
};

// MUTATIONS
const mutations = {
  [types.mutations.SET_LANGUAGE]: (state, language) => {
    if (!state.availableLanguages.includes(language)) {
      return;
    }

    console.log(`[i18n] setting language ${language} with available languages: ${state.availableLanguages.join(',')}`);
    state.language = language;
    dayjs.locale(language);
    i18nConfig.locale = language;
  },
  [types.mutations.SET_LANGUAGE_FROM_QUERY_PARAM]: (state, language) => {
    state.languageFromQueryParam = language;
  },
  [types.mutations.SET_LANGUAGE_FROM_USER_SELECTION]: (state, language) => {
    state.languageFromUserSelection = language;
    i18nStorage.write(STORAGE_KEYS.SELECTED_LANGUAGE, language);
  },
};

// ACTIONS
const actions = {
  [types.actions.RESET]: ({ commit }) => {
    commit(types.mutations.SET_LANGUAGE, cleanState.language);
    commit(types.mutations.SET_LANGUAGE_FROM_QUERY_PARAM, cleanState.languageFromQueryParam);
    i18nStorage.delete(STORAGE_KEYS.SELECTED_LANGUAGE);
  },
  [types.actions.GET_USER_LANGUAGE]: ({ rootGetters, getters }) => {
    const availableLanguages = getters[types.getters.GET_AVAILABLE_LANGUAGES];

    // These are all function so we can short circuit the OR statement
    const languageFromUserSelection = () => getters[types.getters.GET_LANGUAGE_FROM_USER_SELECTION];
    const languageFromMemberAccount = () => rootGetters[memberStoreTypes.getters.GET_MEMBER_LANGUAGE];
    const languageFromLocalStorage = () => {
      const localStorageLanguage = getLocalStorageLanguage();
      return availableLanguages.includes(localStorageLanguage) ? localStorageLanguage : '';
    };
    const languageFromQueryParam = () => getters[types.getters.GET_LANGUAGE_FROM_QUERY_PARAM];
    const languageFromBrowser = () => {
      const browserLanguage = getBrowserLanguage();
      return availableLanguages.includes(browserLanguage) ? browserLanguage : '';
    };
    const languageFromClient = () => {
      const clientLanguage = rootGetters[clientStoreTypes.getters.GET_CLIENT_LANGUAGE];

      if (clientLanguage === 'en') {
        if (availableLanguages.includes('en-GB')) {
          return 'en-GB';
        } if (availableLanguages.includes('en-CA')) {
          return 'en-CA';
        }
      }

      return availableLanguages.includes(clientLanguage) ? clientLanguage : '';
    };
    const defaultLanguage = getters[types.getters.GET_LANGUAGE];

    return languageFromUserSelection()
      || languageFromMemberAccount()
      || languageFromLocalStorage()
      || languageFromQueryParam()
      || languageFromBrowser()
      || languageFromClient()
      || defaultLanguage;
  },
  [types.actions.SET_MEMBER_ACCOUNT_LANGUAGE]: async ({ commit, dispatch }, language) => {
    commit(types.mutations.SET_LANGUAGE_FROM_USER_SELECTION, language);
    try {
      await dispatch(memberStoreTypes.actions.UPDATE_MEMBER, { language }, { root: true });
    } catch (error) {
      console.error(`[i18n] failed to update user with language ${language}`, error);
    }
  },
  [types.actions.UPDATE_APP_LANGUAGE]: async (
    { commit, dispatch },
    { languageFromQueryParam, userSelectedLanguage } = {},
  ) => {
    if (languageFromQueryParam) {
      commit(types.mutations.SET_LANGUAGE_FROM_QUERY_PARAM, languageFromQueryParam);
    }

    if (userSelectedLanguage) {
      await dispatch(types.actions.SET_MEMBER_ACCOUNT_LANGUAGE, userSelectedLanguage);
    }

    const language = await dispatch(types.actions.GET_USER_LANGUAGE);
    commit(types.mutations.SET_LANGUAGE, language);
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters: _getters,
  mutations,
  actions,
};
