import Vue from 'vue';

import Cookies from 'js-cookie';

import { enrolmentPrivateTypes as types } from '@/store/types';
import { enrolmentEndpointTypes } from '@/http/endpoints/EnrolmentEndpoints';
import { anonymousId } from '@/scripts/tracking-util';
import DIRECTION from '@/scripts/constants/navigation';

// STATE
const cleanState = {
  enrolmentUUID: '',
  correlationUUID: '',
  dynamicUnitFlowUUID: '',
  inviteData: null,
  recovering: true,
  isRescheduling: false,
  version: 1,
  samlV2: null,
  externalIntegration: null,
  isSupportChatEnabled: false,
  bloomSkipCallFlow: null,
  navigationDirection: DIRECTION.NEXT_OR_SUBMIT,
};

const defaultState = () => ({
  ...cleanState,
});

// GETTERS
const _getters = {
  [types.getters.GET_ENROLMENT_UUID]: state => state.enrolmentUUID,
  [types.getters.GET_CORRELATION_UUID]: state => state.correlationUUID,
  [types.getters.GET_DYNAMIC_UNIT_FLOW_UUID]: state => state.dynamicUnitFlowUUID,
  [types.getters.GET_INVITE_DATA]: state => state.inviteData,
  [types.getters.IS_RECOVERING]: state => state.recovering,
  [types.getters.IS_RESCHEDULING]: state => state.isRescheduling,
  [types.getters.GET_VERSION]: state => state.version,
  [types.getters.GET_EXTERNAL_INTEGRATION]: state => state.externalIntegration,
  [types.getters.HAS_ENROLLMENT]: state => !!state.enrolmentUUID,
  [types.getters.GET_BLOOM_SKIP_CALL_FLOW]: state => state.bloomSkipCallFlow,
  [types.getters.GET_NAVIGATION_DIRECTION]: state => state.navigationDirection,
  [types.getters.GET_SUPPORT_CHAT_ENABLED]: state => state.isSupportChatEnabled,
};

// MUTATIONS
const mutations = {
  [types.mutations.SET_SUPPORT_CHAT_ENABLED](state, isEnabled) {
    state.isSupportChatEnabled = isEnabled;
  },
  [types.mutations.SET_ENROLMENT_UUID](state, enrolmentUUID) {
    state.enrolmentUUID = enrolmentUUID;
  },
  [types.mutations.SET_CORRELATION_UUID](state, correlationUUID) {
    state.correlationUUID = correlationUUID;
  },
  [types.mutations.SET_DYNAMIC_UNIT_FLOW_UUID](state, dynamicUnitFlowUUID) {
    state.dynamicUnitFlowUUID = dynamicUnitFlowUUID;
  },
  [types.mutations.SET_INVITE_DATA](state, inviteData) {
    state.inviteData = inviteData;
  },
  [types.mutations.SET_RECOVERING](state, recovering) {
    state.recovering = recovering;
  },
  [types.mutations.SET_RESCHEDULING](state, isRescheduling) {
    state.isRescheduling = isRescheduling;
  },
  [types.mutations.SET_VERSION](state, version) {
    state.version = version;
  },
  [types.mutations.SET_EXTERNAL_INTEGRATION](state, externalIntegration) {
    state.externalIntegration = externalIntegration;
  },
  [types.mutations.SET_BLOOM_SKIP_CALL_FLOW](state, bloomSkipCallFlow) {
    state.bloomSkipCallFlow = bloomSkipCallFlow;
  },
  [types.mutations.SET_NAVIGATION_DIRECTION](state, navigationDirection) {
    state.navigationDirection = navigationDirection;
  },
};

// ACTIONS
const actions = {
  [types.actions.SET_SUPPORT_CHAT_ENABLED]({ commit }, { enable }) {
    commit(types.mutations.SET_SUPPORT_CHAT_ENABLED, enable);
  },
  async [types.actions.START_ENROLMENT]({ commit }, { payload }) {
    const { data } = await Vue.$http(enrolmentEndpointTypes.START_ENROLMENT, null, {
      config: {
        headers: { 'x-tracking-id': anonymousId() },
      },
      body: payload,
    });

    commit(types.mutations.SET_ENROLMENT_UUID, data.uuid);
    if (data.metadata?.dynamic_unit_flow_uuid) {
      commit(types.mutations.SET_DYNAMIC_UNIT_FLOW_UUID, data.metadata.dynamic_unit_flow_uuid);
    }
    return data;
  },

  [types.actions.LOAD_EXTERNAL_INTEGRATION]({ commit }) {
    const externalCookie = Cookies.get('external_integration');
    if (externalCookie) {
      try {
        const decoded = Buffer.from(externalCookie, 'base64').toString('utf-8');
        commit(types.mutations.SET_EXTERNAL_INTEGRATION, JSON.parse(decoded));
        Cookies.remove('external_integration');
      } catch (e) {
        console.error('failed to parse external_integration cookie', e);
      }
    }
  },

  async [types.actions.LOAD_BLOOM_SKIP_CALL_FLOW]({ commit }, { enrolmentUUID }) {
    if (this.getters[types.getters.GET_BLOOM_SKIP_CALL_FLOW]) {
      return this.getters[types.getters.GET_BLOOM_SKIP_CALL_FLOW];
    }

    const uuid = enrolmentUUID || this.getters[types.getters.GET_ENROLMENT_UUID];

    const {
      data: {
        data: {
          skip_flow: skipFlow,
        },
      },
    } = await Vue.$http(
      enrolmentEndpointTypes.GET_CALL_PREFERENCES_DECISION,
      { uuid },
    );

    commit(types.mutations.SET_BLOOM_SKIP_CALL_FLOW, skipFlow);

    return skipFlow;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters: _getters,
  mutations,
  actions,
};
