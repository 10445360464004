import { AnalyticsBrowser } from '@segment/analytics-next';
import { env } from '@/config';
import useDomainChecker from '@/composables/domainChecker';

const { isBlossom } = useDomainChecker();

const analytics = new AnalyticsBrowser();

export function injectTracking() {
  analytics.load({
    writeKey: isBlossom.value && !!env.VUE_APP_SEGMENT_BLOSSOM_API_KEY
      ? env.VUE_APP_SEGMENT_BLOSSOM_API_KEY
      : env.VUE_APP_SEGMENT_API_KEY,
  });
  window.analytics = analytics;
}

export function sendTrackingPageView({ path, subpage }) {
  analytics.page({
    url: path,
    subpage,
  });
}

export function anonymousId() {
  // use segment computed anonymous id
  const segmentID = analytics.instance?.user().anonymousId();
  if (segmentID) {
    return segmentID;
  }

  // adblock may be preventing segment from loading, try local storage
  const localID = JSON.parse(localStorage?.getItem('ajs_anonymous_id') || '""');
  if (localID) {
    return localID;
  }

  // generate own id when all else fails
  const id = crypto?.randomUUID();
  if (id) {
    localStorage?.setItem('ajs_anonymous_id', id);
  }

  return id;
}

export function sendTrackingEvent(eventName, eventValue = {}) {
  analytics.track(eventName, eventValue);
}

export function sendTrackingIdentity({ fingerprint, accountID }, traits = {}) {
  const identity = fingerprint || accountID;
  if (identity) {
    analytics.identify(identity, traits);
  } else {
    analytics.identify(traits);
  }
}

