import Vue from 'vue';
import '@/config';
import '@/scripts/app-configs/stackdriver-logger-configs';
import '@/plugins/http-config';
import '@/plugins/vuelidate-config';
import '@/plugins/vue2-touch';
import '@/plugins/dayjs-config';
import '@/styles/main.scss';
import '@/plugins/page-info';
import '@/plugins/tooltip/v-tooltip';
import '@/plugins/viewport-ios';
import '@/plugins/zendesk';
import '@/plugins/portal-vue';
import { newrelic } from '@sword-health/ui-core';
import { InlineSvgPlugin } from 'vue-inline-svg';
import { NOTIFY_ALIAS, toast } from '@ui-kit/scripts/configs/notify-config';
import i18n from '@/plugins/i18n-config';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import { applicationStoreTypes } from '@/store/types';

import 'animate.css';
import 'hooper/dist/hooper.css';

newrelic.listen();
Vue.use(InlineSvgPlugin);

Vue.config.errorHandler = function errHandler(err, vm, info) {
  newrelic.noticeError(err, { info });
  console.error(err);
};

Vue.config.productionTip = false;

Vue[NOTIFY_ALIAS] = toast;
Object.defineProperties(Vue.prototype, {
  [NOTIFY_ALIAS]: {
    get() {
      return toast;
    },
  },
});

Vue.prototype.$t = (key, ...args) => {
  const unit = store.getters[applicationStoreTypes.getters.UNIT];
  if (unit && unit.t && unit.t[key]) return i18n.t(unit.t[key], ...args);
  return i18n.t(key, ...args);
};

Vue.prototype.$mappedTranslationKey = key => {
  const unit = store.getters[applicationStoreTypes.getters.UNIT];
  if (unit && unit.t && unit.t[key]) return unit.t[key];
  return key;
};


new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
