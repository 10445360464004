import Vue from 'vue';
import apiInterface from '@/scripts/api-interface';

const defaultRescheduleState = () => ({
  initialSchedule: {
    time: '',
    timezone: '',
    date: '',
    dateWithOffset: '',
  },
});

export default {
  namespaced: true,
  state: defaultRescheduleState(),
  getters: {
    getInitialScheduleData: state => prop => (prop ? state.initialSchedule[prop] : state.initialSchedule),
  },
  mutations: {
    setInitialScheduleData(state, payload) {
      Object.keys(payload).forEach(prop => {
        state.initialSchedule[prop] = payload[prop];
      });
    },
  },
  actions: {
    async submitReschedule({ rootGetters, commit }, params) {
      commit('appointment/setAsRetrying', false, { root: true });
      const schedule = rootGetters['appointment/getScheduleData']();
      const rescheduleData = apiInterface.generateScheduleBody({ schedule }, params.therapistId);
      const options = {
        body: rescheduleData,
        config: {
          headers: {
            'x-redirect-url': `${window.location.pathname}${window.location.search}`,
          },
        },
      };

      return Vue.$http('appointment/submitReschedule', params, options);
    },
  },
};
