import Vue from 'vue';
import { oneQuestionPerScreenPrivateTypes as types } from '@/store/types';

const defaultOneQuestionPerScreenState = () => ({
  lastSubmittedPages: {},
});

export default {
  namespaced: true,
  state: defaultOneQuestionPerScreenState(),
  getters: {
    [types.getters.GET_LAST_SUBMITTED_PAGES]: state => (type, key) => state.lastSubmittedPages[`${type}/${key}`],
  },
  mutations: {
    [types.mutations.UPDATE_LAST_SUBMITTED_PAGES](state, { type, key, page }) {
      Vue.set(state.lastSubmittedPages, `${type}/${key}`, page);
    },
    [types.mutations.SET_LAST_SUBMITTED_PAGES]: (state, lastSubmittedPages) => {
      state.lastSubmittedPages = lastSubmittedPages;
    },
  },
  actions: {
    [types.actions.UPDATE_LAST_SUBMITTED_PAGES]({ commit }, { type, key, page }) {
      commit(types.mutations.UPDATE_LAST_SUBMITTED_PAGES, { type, key, page });
    },
    [types.actions.RESET_LAST_SUBMITTED_PAGES]: ({ commit }) => {
      commit(types.mutations.SET_LAST_SUBMITTED_PAGES, {});
    },
  },
};
