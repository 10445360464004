import { watch } from 'vue';
import useDomainChecker from '@/composables/domainChecker';
import useStore from '@/composables/useStore';
import { applicationStoreTypes } from '@/store/types';

export default function useFavicon() {
  const store = useStore();
  const { isBlossom } = useDomainChecker();
  const unitName = store.getGetter(applicationStoreTypes.getters.UNIT_NAME);

  const FAVICON_32 = 'favicon-32x32.png';
  const FAVICON_16 = 'favicon-16x16.png';

  const headElement = document.getElementsByTagName('head')[0];

  const removeExistingFavicons = () => {
    const existingIcons = document.querySelectorAll('link[rel="icon"]');
    existingIcons.forEach(icon => icon.remove());
  };

  const createFaviconLink = (size, href) => {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/png';
    link.sizes = size;
    link.href = href;
    return link;
  };

  const updateFavicon = () => {
    if (!headElement) return;

    removeExistingFavicons();

    const basePath = isBlossom.value ? 'blossom' : unitName.value;
    const link32 = createFaviconLink('32x32', `/v3/dist/${basePath}/${FAVICON_32}`);
    const link16 = createFaviconLink('16x16', `/v3/dist/${basePath}/${FAVICON_16}`);
    headElement.appendChild(link32);
    headElement.appendChild(link16);
  };

  watch([isBlossom, unitName], updateFavicon, { immediate: true });
}
