import { watch, ref } from 'vue';
import useDomainChecker from '@/composables/domainChecker';
import config from '@/config';

const { unit } = config;

export default function useTitle() {
  const { isBlossom } = useDomainChecker();

  const pageTitle = ref(isBlossom.value ? 'Blossom' : unit.name);

  // Watch the title and update document.title when it changes
  watch(pageTitle, newTitle => {
    document.title = newTitle;
  }, { immediate: true });

  const setTitle = newTitle => {
    pageTitle.value = newTitle;
  };

  // Return the title ref and the setTitle function for external use
  return {
    pageTitle,
    setTitle,
  };
}
