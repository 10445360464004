import { ref, computed, watchEffect } from 'vue';

export const DOMAIN_NAMES = {
  BECOME: 'become',
  BLOSSOM: 'blossom',
  ONBOARDING: 'onboarding',
};

export default function useDomainChecker() {
  const hostname = ref(window.location.hostname);
  const defaultDomain = { name: DOMAIN_NAMES.ONBOARDING, domain: 'swordhealth.com' };

  const domainMap = [
    { name: DOMAIN_NAMES.BECOME, domain: 'joinbecome.com' },
    { name: DOMAIN_NAMES.BLOSSOM, domain: 'meetblossom.com' },
  ];

  const currentDomain = computed(() => {
    const foundDomain = domainMap.find(({ domain }) => hostname.value.includes(domain));
    return foundDomain || defaultDomain;
  });

  const isBlossom = computed(() => currentDomain.value.name === DOMAIN_NAMES.BLOSSOM);

  const isBecome = computed(() => currentDomain.value.name === DOMAIN_NAMES.BECOME);

  watchEffect(() => {
    hostname.value = window.location.hostname;
  });

  return {
    currentDomain,
    isBlossom,
    isBecome,
  };
}
