import { getCurrentInstance, computed } from 'vue';

export default function useStore(namespace) {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error('useStore must be called inside setup function');
  }
  const $store = vm.proxy.$store;

  function getAction(action) {
    // eslint-disable-next-line func-names
    return function (...args) {
      if (!namespace) {
        $store.dispatch(action, ...args);
      } else {
        $store.dispatch(`${namespace}/${action}`, ...args);
      }
    };
  }

  function getMutation(mutation) {
    // eslint-disable-next-line func-names
    return function (...args) {
      if (!namespace) {
        $store.commit(mutation, ...args);
      } else {
        $store.commit(`${namespace}/${mutation}`, ...args);
      }
    };
  }

  function getState(state) {
    if (!namespace) {
      return computed(() => $store.state[state]);
    }
    return computed(() => $store.state[namespace][state]);
  }

  function getGetter(getter) {
    if (!namespace) {
      return computed(() => $store.getters[getter]);
    }
    return computed(() => $store.getters[`${namespace}/${getter}`]);
  }

  function subscribe(subscriber) {
    return $store.subscribe(subscriber);
  }

  function subscribeAction(subscriber) {
    return $store.subscribeAction(subscriber);
  }

  function dispatch(action, payload) {
    if (!namespace) {
      return $store.dispatch(action, payload);
    }
    return $store.dispatch(`${namespace}/${action}`, payload);
  }

  return {
    getState,
    getGetter,
    getMutation,
    getAction,
    subscribe,
    subscribeAction,
    dispatch,
  };
}
