import {
  evaluateGuards, authGuard, trackingGuard, clientGuard, enrollmentGuard,
} from '@/router/guards';

const defaultFormGuards = [authGuard, trackingGuard, clientGuard];

const virtualPTRoutes = [
  {
    name: 'DynamicOnboardingStep',
    path: 'step/:type/:key',
    component: () => import(/* webpackChunkName: "DynamicStep" */ '../views/dynamic-virtual-pt/DynamicStep.vue'),
    beforeEnter: evaluateGuards([authGuard, clientGuard, enrollmentGuard]),
    meta: {
      considerSupportChatEnabled: true,
    },
  },
  {
    name: 'EnrolmentError',
    path: 'error',
    component: () => import(/* webpackChunkName: "ErrorFeedback" */ '../views/feedbacks/ErrorFeedback.vue'),
    beforeEnter: evaluateGuards([trackingGuard, authGuard, clientGuard]),
    props: true,
  },
  {
    name: 'WorkerCompensationError',
    path: 'demographics/worker-compensation-error',
    props: {
      title: 'onboarding2_title_message_thanks',
      text: 'eco_workers_compensation_message',
      buttonText: 'onboarding_go_back',
      routeName: 'Programs',
    },
    component: () => import(/* webpackChunkName: "WorkerCompensationError" */ '../views/feedbacks/GenericFeedbackScreen.vue'),
    beforeEnter: evaluateGuards(defaultFormGuards),
  },
];

export default virtualPTRoutes;
