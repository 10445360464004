import Vue from 'vue';
import { tools } from '@sword-health/ui-core';

import Cookies from 'js-cookie';
import {
  authPrivateTypes as types,
  memberStoreTypes,
  clientStoreTypes,
} from '@/store/types';
import { STORAGE_KEYS, AUTH_ERROR_REASONS } from '@/scripts/constants';
import { anonymousId } from '@/scripts/tracking-util';
import { saveMultimodeInviteId, deleteMultimodeInviteId } from '@/storage/invitationStorage';
import { authEndpointsTypes } from '@/http/endpoints/AuthEndpoints';

function decodeJWT(token) {
  const { 1: base64Url } = token.split('.');
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
}

function expToDate(exp) {
  return new Date(exp * 1000);
}

function saveTokenInCookie(cookieName, token) {
  if (token) {
    const { exp } = decodeJWT(token);
    const expDate = expToDate(exp);

    Cookies.set(cookieName, token, { expires: expDate });
  }
}

// INITIALIZATION
const authStorage = new tools.StorageInterface(localStorage);

// STATE
const cleanState = {
  authData: {
    accessToken: null,
    refreshToken: null,
  },
  lockedLoginData: {
    isLocked: false,
    isBlocked: false, // locked indefinitely
    loginAttemptAt: null,
    isPasswordBroken: false,
  },
  authHasTimedout: false,
  clientReference: null,
  isUserJustLoggedIn: false,
};
const defaultState = () => ({
  // Load the `authData` into `LOAD_STORED_AUTH_TOKENS` action (and not here),
  // because that way we can have `SET_AUTH_DATA` trigger, that is needed in http-config file
  ...cleanState,
  lockedLoginData: authStorage.read(STORAGE_KEYS.LOCKED_LOGIN) || cleanState.lockedLoginData,
});

// GETTERS
const _getters = {
  [types.getters.IS_LOGGED_IN]: state => !!state.authData.accessToken,
  [types.getters.GET_ACCESS_TOKEN]: state => state.authData.accessToken,
  [types.getters.GET_REFRESH_TOKEN]: state => state.authData.refreshToken,
  [types.getters.GET_AUTH_HAS_TIMEDOUT]: state => state.authHasTimedout,
  [types.getters.GET_CLIENT_REFERENCE]: state => state.clientReference,

  [types.getters.GET_LOCKED_LOGIN_INFO]: state => {
    const {
      isLocked, isBlocked, isPasswordBroken,
    } = state.lockedLoginData;

    return {
      isLocked,
      isBlocked,
      isPasswordBroken,
    };
  },
};

// MUTATIONS
const mutations = {
  [types.mutations.SET_AUTH_DATA]: (state, authData) => {
    const { accessToken, refreshToken } = authData;
    state.authData = {
      accessToken,
      refreshToken,
    };
  },

  [types.mutations.SET_ACCESS_TOKEN]: (state, accessToken) => {
    state.authData.accessToken = accessToken;
  },

  [types.mutations.LOAD_STORED_AUTH_TOKENS]: state => {
    const authCookie = Cookies.get('auth');
    if (authCookie) {
      try {
        const decoded = Buffer.from(authCookie, 'base64').toString('utf-8');
        const { access_token: accessToken, refresh_token: refreshToken } = JSON.parse(decoded);

        saveTokenInCookie(STORAGE_KEYS.ACCESS_TOKEN, accessToken);
        saveTokenInCookie(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
        Cookies.remove('auth');
      } catch (e) {
        console.error('failed to parse auth cookie', e);
      }
    }

    const accessToken = Cookies.get(STORAGE_KEYS.ACCESS_TOKEN);
    const refreshToken = Cookies.get(STORAGE_KEYS.REFRESH_TOKEN);

    state.authData = {
      accessToken,
      refreshToken,
    };
  },

  [types.mutations.SET_LOCKED_LOGIN_DATA]: (state, lockedLoginData) => {
    const {
      isLocked, isBlocked, loginAttemptAt, isPasswordBroken,
    } = lockedLoginData;
    state.lockedLoginData = {
      isLocked,
      isBlocked,
      loginAttemptAt,
      isPasswordBroken,
    };
  },

  [types.mutations.SET_AUTH_HAS_TIMEDOUT]: (state, timedout) => {
    state.authHasTimedout = timedout;
  },

  [types.mutations.SET_CLIENT_REFERENCE]: (state, clientReference) => {
    state.clientReference = clientReference;
  },

  [types.mutations.SET_USER_JUST_LOGGED_IN]: (state, isUserJustLoggedIn) => {
    state.isUserJustLoggedIn = isUserJustLoggedIn;
  },
};

// ACTIONS
const actions = {
  [types.actions.SAVE_AUTH_DATA]: ({ commit }, authData) => {
    const { accessToken, refreshToken } = authData;

    saveTokenInCookie(STORAGE_KEYS.ACCESS_TOKEN, accessToken);
    saveTokenInCookie(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);

    commit(types.mutations.SET_AUTH_DATA, {
      accessToken,
      refreshToken,
    });
  },
  [types.actions.SAVE_ACCESS_TOKEN]: ({ commit }, accessToken) => {
    saveTokenInCookie(STORAGE_KEYS.ACCESS_TOKEN, accessToken);

    commit(types.mutations.SET_ACCESS_TOKEN, accessToken);
  },
  [types.actions.SAVE_LOCKED_LOGIN_DATA]: async ({ commit }, lockedData) => {
    const { isLocked, isBlocked, isPasswordBroken } = lockedData;
    const lockedLoginData = {
      isLocked,
      isBlocked,
      loginAttemptAt: Date.now(),
      isPasswordBroken,
    };

    authStorage.write(STORAGE_KEYS.LOCKED_LOGIN, lockedLoginData);
    commit(types.mutations.SET_LOCKED_LOGIN_DATA, lockedLoginData);
  },

  [types.actions.RESET_LOCKED_LOGIN_DATA]: ({ commit }) => {
    authStorage.delete(STORAGE_KEYS.LOCKED_LOGIN);
    commit(types.mutations.SET_LOCKED_LOGIN_DATA, cleanState.lockedLoginData);
  },

  [types.actions.LOGIN]: async ({ getters, dispatch, commit }, credentials) => {
    const clientReference = getters[types.getters.GET_CLIENT_REFERENCE];
    const { email, password, multimodeInviteId } = credentials;

    try {
      const body = { email, password, ...(clientReference && { client_reference: clientReference }) };

      if (multimodeInviteId) {
        body.multimode_invite_uuid = multimodeInviteId;
      }

      const options = {
        body,
        config: {
          headers: {
            'x-tracking-id': anonymousId(),
            'x-redirect-url': `${window.location.pathname}${window.location.search}`,
          },
        },
      };

      const { data: loginData } = await Vue.$http(authEndpointsTypes.LOGIN, null, options);

      if (multimodeInviteId) { deleteMultimodeInviteId(); }
      commit(types.mutations.SET_CLIENT_REFERENCE, null);
      saveMultimodeInviteId(loginData.multimode_invite_uuid);

      await dispatch(types.actions.SAVE_AUTH_DATA, {
        accessToken: loginData.access_token,
        refreshToken: loginData.refresh_token,
      });
      await dispatch(memberStoreTypes.actions.GET_MEMBER, null, { root: true });
      commit(types.mutations.SET_AUTH_HAS_TIMEDOUT, false);
    } catch (error) {

      const errorsData = error?.response?.data?.errors || [];

      errorsData.every(errorData => {
        const errorReason = errorData?.reason;

        if (errorReason === AUTH_ERROR_REASONS.ACCOUNT_BLOCKED) {
          console.log('Login error because the account is blocked');
          dispatch(types.actions.SAVE_LOCKED_LOGIN_DATA, {
            isLocked: false,
            isBlocked: true,
            isPasswordBroken: false,
          });
          return false;
        }

        if (errorReason === AUTH_ERROR_REASONS.ACCOUNT_SUSPENDED) {
          console.log('Login error because the account is suspended');
          dispatch(types.actions.SAVE_LOCKED_LOGIN_DATA, {
            isLocked: true,
            isBlocked: false,
            isPasswordBroken: false,
          });
          return false;
        }

        if (errorReason === AUTH_ERROR_REASONS.PASSWORD_BROKEN) {
          console.log('Login error because the password is broken');
          dispatch(types.actions.SAVE_LOCKED_LOGIN_DATA, {
            isLocked: false,
            isBlocked: false,
            isPasswordBroken: true,
          });
          return false;
        }

        return true;
      });

      throw error;
    }
  },

  [types.actions.LOGOUT]: ({ commit, dispatch }) => {
    Cookies.remove(STORAGE_KEYS.ACCESS_TOKEN);
    Cookies.remove(STORAGE_KEYS.REFRESH_TOKEN);
    deleteMultimodeInviteId();

    commit(clientStoreTypes.mutations.RESET_STATE, null, { root: true });
    dispatch(memberStoreTypes.actions.RESET_MEMBER, null, { root: true });

    window.onbeforeunload = undefined;
    window.location = '/account/login';
  },

  [types.actions.REFRESH_TOKENS]: async ({ dispatch }) => {
    try {
      const { data: refreshTokensData } = await Vue.$http(authEndpointsTypes.REFRESH_TOKENS);
      const accessToken = refreshTokensData.access_token;

      dispatch(types.actions.SAVE_ACCESS_TOKEN, accessToken);

      return { accessToken };
    } catch (error) {
      console.log('Failed to request refresh token then logout called');
      dispatch(types.actions.LOGOUT);
      throw error;
    }
  },

  [types.actions.AUTH_TIMEDOUT]: ({ commit, dispatch }) => {
    commit(types.mutations.SET_AUTH_HAS_TIMEDOUT, true);
    dispatch(types.actions.LOGOUT);
  },

  [types.actions.RECOVER_PASSWORD]: async (store, email) => Vue.$http(authEndpointsTypes.RECOVER_PASSWORD, null, { body: { email } }),

  [types.actions.RESET_PASSWORD]: async (store, { token, password, passwordConfirmation }) => (
    Vue.$http(authEndpointsTypes.RESET_PASSWORD, null, {
      body: {
        token,
        password,
        password_confirmation: passwordConfirmation,
      },
    })
  ),

  [types.actions.REQUEST_ACCOUNT_DELETE]: async (store, { mainReason, additionalReasons }) => Vue.$http(
    authEndpointsTypes.REQUEST_ACCOUNT_DELETE,
    null,
    {
      body: {
        main_reason: mainReason,
        additional_reasons: additionalReasons,
      },
    },
  ),

  [types.actions.CONFIRM_ACCOUNT_DELETE]: async (store, token) => Vue.$http(
    authEndpointsTypes.CONFIRM_ACCOUNT_DELETE,
    null,
    { body: { token } },
  ),
};

export default {
  namespaced: true,
  state: defaultState,
  getters: _getters,
  mutations,
  actions,
};
