import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store/index';
import { authStoreTypes } from '@/store/types';
import {
  evaluateGuards,
  authGuard,
  trackingGuard,
  clientGuard,
  dptGoGuard,
  triageGuard,
} from '@/router/guards';
import virtualPTRoutes from '@/router/virtual-pt-routes';
import registrationRoutes from '@/router/registration-routes';
import { usePageInfo } from '@/plugins/page-info';
import { delayedScrollToTop, DYNAMIC_FORMS_ANIMATION_DELAY } from '@/scripts/utils';

Vue.use(VueRouter);

const appointmentRoutes = [
  {
    path: '/appointment/:appointmentUUID/reschedule',
    component: () => import('../components/layout/Layout.vue'),
    children: [
      {
        name: 'ScheduleForm',
        path: '',
        component: () => import(/* webpackChunkName: "Reschedule" */ '../views/reschedule/ScheduleForm.vue'),
      },
      {
        name: 'ScheduleSuccess',
        path: 'success',
        component: () => import(
          /* webpackChunkName: "Feedbacks" */ '../views/feedbacks/RescheduleSuccessScreen.vue'
        ),
      },
      {
        name: 'ScheduleError',
        path: 'error',
        component: () => import(
          /* webpackChunkName: "Feedbacks" */ '../views/feedbacks/RescheduleErrorScreen.vue'
        ),
      },
      {
        name: 'SchedulePTSelect',
        path: 'pt-select',
        component: () => import(/* webpackChunkName: "PTSelection" */ '../views/reschedule/SchedulePTSelect.vue'),
      },
    ],
  },
];

const memberAccountRoutes = [
  {
    path: '/account/login',
    alias: '/',
    name: 'Login',
    beforeEnter: trackingGuard,
    component: () => import(/* webpackChunkName: "Login" */ '../views/member-account/login/Login.vue'),
  },
  {
    path: '/account/login',
    component: () => import('../components/layout/Layout.vue'),
    children: [
      {
        path: 'password-broken',
        name: 'PasswordBroken',
        component: () => import(/* webpackChunkName: "Login" */ '../views/feedbacks/PasswordBrokenScreen.vue'),
      },
      {
        path: 'locked',
        name: 'LockedLogin',
        component: () => import(/* webpackChunkName: "Login" */ '../views/member-account/login/LockedLogin.vue'),
      },
    ],
  },
  {
    path: '/account/recover-password',
    component: () => import('../components/layout/Layout.vue'),
    children: [
      {
        path: '',
        name: 'PasswordRecovery',
        component: () => import(
          /* webpackChunkName: "RecoverPassword" */ '../views/member-account/password-recovery/PasswordRecovery.vue'
        ),
      },
      {
        path: 'success',
        name: 'PasswordRecoverySuccess',
        component: () => import(
          /* webpackChunkName: "RecoverPassword" */ '../views/member-account/password-recovery/PasswordRecoverySuccess.vue'
        ),
      },
    ],
  },
  {
    path: '/account/delete',
    beforeEnter: authGuard,
    component: () => import('../components/layout/Layout.vue'),
    children: [
      {
        path: '/',
        name: 'DeleteAccount',
        beforeEnter: authGuard,
        component: () => import(
          /* webpackChunkName: "DeleteAccount" */ '../views/member-account/delete/DeleteAccount.vue'
        ),
      },
      {
        path: 'success',
        name: 'DeleteAccountSuccess',
        beforeEnter: authGuard,
        component: () => import(
          /* webpackChunkName: "DeleteAccount" */ '../views/member-account/delete/DeleteAccountSuccess.vue'
        ),
      },
    ],
  },
  {
    path: '/account/delete',
    component: () => import('../components/layout/Layout.vue'),
    children: [
      {
        path: 'expired',
        name: 'DeleteAccountExpired',
        component: () => import(
          /* webpackChunkName: "DeleteAccount" */ '../views/member-account/delete/DeleteAccountExpired.vue'
        ),
      },
      {
        path: ':token',
        name: 'DeleteAccountFinalized',
        component: () => import(
          /* webpackChunkName: "DeleteAccount" */ '../views/member-account/delete/DeleteAccountFinalized.vue'
        ),
        beforeEnter: async (to, from, next) => {
          const { token } = to.params;
          try {
            await store.dispatch(authStoreTypes.actions.CONFIRM_ACCOUNT_DELETE, token);
            next();
          } catch (error) {
            console.warn('Failed to delete an account: delete account expired');
            next({ name: 'DeleteAccountExpired' });
          }
        },
      },
    ],
  },
  {
    path: '/account/reset-password',
    component: () => import('../components/layout/Layout.vue'),
    children: [
      {
        path: 'success',
        name: 'PasswordResetSuccess',
        component: () => import(
          /* webpackChunkName: "ResetPassword" */ '../views/member-account/password-reset/PasswordResetSuccess.vue'
        ),
      },
      {
        path: 'expired',
        name: 'PasswordResetExpired',
        component: () => import(
          /* webpackChunkName: "ResetPassword" */ '../views/member-account/password-reset/PasswordResetExpired.vue'
        ),
      },
      {
        path: ':token',
        name: 'PasswordReset',
        component: () => import(
          /* webpackChunkName: "ResetPassword" */ '../views/member-account/password-reset/PasswordReset.vue'
        ),
        props: true,
      },
    ],
  },
  {
    path: '/account/sso/authenticated',
    component: () => import('../components/layout/Layout.vue'),
    props: false,
    children: [
      {
        path: '',
        name: 'SSOAuth',
        props: false,
        component: () => import(/* webpackChunkName: "SSOAuth" */ '../views/sso/SSOAuth.vue'),
      },
    ],
  },
  {
    path: '/account/sso/:ssoName',
    component: () => import('../components/layout/Layout.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'SSORedirect',
        props: true,
        component: () => import(/* webpackChunkName: "SSORedirect" */ '../views/sso/SSORedirect.vue'),
      },
    ],
  },
  {
    path: '/v3/sso/authenticated',
    component: () => import('../components/layout/Layout.vue'),
    children: [
      {
        path: '',
        name: 'SSOAuth',
        component: () => import(/* webpackChunkName: "SSOAuth" */ '../views/sso/SSOAuth.vue'),
      },
    ],
  },
  {
    path: '/on-call/feedback',
    name: 'OnCallFeedback',
    component: () => import(/* webpackChunkName: "OnCallFeedback" */ '../views/on-call/OnCallFeedback.vue'),
  },
  {
    path: '/nps/feedback',
    name: 'NPSFeedback',
    component: () => import(/* webpackChunkName: "NPSFeedback" */ '../views/nps/NPSFeedback.vue'),
  },
  {
    path: '/saml/error',
    name: 'SSOError',
    component: () => import(/* webpackChunkName: "SSOError" */ '../views/sso/SSOError.vue'),
  },
];

const clientRoutes = [
  {
    path: '/noclient',
    name: 'noclient',
    component: () => import(/* webpackChunkName: "Landing" */ '../views/InvalidClient.vue'),
  },
  {
    path: '/v3/c/:clientRef',
    name: 'client',
    beforeEnter: evaluateGuards([
      clientGuard,
      async (to, from, next) => {
        store.commit(authStoreTypes.mutations.LOAD_STORED_AUTH_TOKENS);
        const isLoggedIn = store.getters[authStoreTypes.getters.IS_LOGGED_IN];
        const routeName = isLoggedIn ? 'Programs' : 'Registration';
        return next({ name: routeName, params: to.params, query: to.query });
      },
    ]),
  },
  {
    path: '/v3/c/:clientRef/account',
    name: 'Registration',
    beforeEnter: evaluateGuards([clientGuard]),
    component: () => import(/* webpackChunkName: "RegistrationAccountStep" */ '../views/Registration.vue'),
    redirect: to => ({ name: 'MemberStep', params: to.params, query: to.query }),
    children: registrationRoutes,
  },
  {
    path: '/v3/c/:clientRef/preventive',
    component: () => import('../components/layout/Layout.vue'),
    beforeEnter: evaluateGuards([trackingGuard, authGuard, clientGuard]),
    children: [
      {
        path: '',
        name: 'PreventiveEnrolment',
        component: () => import(/* webpackChunkName: "Preventive" */ '../views/preventive/FeedbackPreventive.vue'),
      },
    ],
  },
  {
    path: '/v3/c/:clientRef/screening',
    name: 'VirtualPTEnrolment',
    beforeEnter: evaluateGuards([authGuard, clientGuard]),
    component: () => import(/* webpackChunkName: "VirtualPT" */ '../components/layout/Layout.vue'),
    children: virtualPTRoutes,
    props: { programRoutes: virtualPTRoutes },
  },
  {
    path: '/v3/c/:clientRef/invite/error',
    component: () => import('../components/layout/Layout.vue'),
    beforeEnter: evaluateGuards([clientGuard]),
    children: [
      {
        path: '',
        name: 'OpenInviteError',
        beforeEnter: evaluateGuards([clientGuard]),
        component: () => import(/* webpackChunkName: "OpenInvite" */ '../views/feedbacks/TryAgainError.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/v3/c/:clientRef/invite/:token',
    component: () => import('../components/layout/Layout.vue'),
    beforeEnter: evaluateGuards([clientGuard]),
    children: [
      {
        path: '',
        name: 'OpenInvite',
        component: () => import(/* webpackChunkName: "OpenInvite" */ '../views/invite/OpenInvite.vue'),
      },
    ],
  },
  {
    path: '/v3/c/:clientRef/:invitationUUID',
    component: () => import('../components/layout/Layout.vue'),
    beforeEnter: evaluateGuards([clientGuard]),
    children: [
      {
        path: '',
        name: 'SendInvite',
        component: () => import(/* webpackChunkName: "SendInvite" */ '../views/invite/SendInvite.vue'),
        beforeEnter: evaluateGuards([clientGuard]),
      },
      {
        path: 'success',
        name: 'SendInviteSuccess',
        component: () => import(/* webpackChunkName: "SendInvite" */ '../views/invite/SendInviteSuccess.vue'),
        beforeEnter: evaluateGuards([clientGuard]),
        props: true,
      },
      {
        path: 'error',
        name: 'SendInviteError',
        component: () => import(/* webpackChunkName: "SendInvite" */ '../views/feedbacks/ErrorFeedback.vue'),
        beforeEnter: evaluateGuards([clientGuard]),
      },
    ],
  },
];

const programsRoutes = [
  {
    path: '/programs',
    component: () => import(/* webpackChunkName: "Programs" */ '../views/virtual-pt/programs/Programs.vue'),
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: 'Programs',
        component: () => import(/* webpackChunkName: "Programs" */ '../views/virtual-pt/programs/Navigator.vue'),
        beforeEnter: evaluateGuards([dptGoGuard, trackingGuard]),
      },
      {
        path: ':program/get-started',
        name: 'GetStarted',
        component: () => import(/* webpackChunkName: "Programs" */ '../views/virtual-pt/programs/GetStarted.vue'),
        beforeEnter: evaluateGuards([
          (to, from, next) => {
            if (!['dpt', 'bloom'].includes(to.params.program)) {
              return next({
                name: 'Programs',
              });
            }

            return next();
          },
          trackingGuard,
        ]),
        props: true,
      },
      {
        path: ':program/detail',
        name: 'ProgramDetail',
        component: () => import(
          /* webpackChunkName: "Programs" */ '../views/virtual-pt/programs/ProgramDetail.vue'
        ),
        beforeEnter: trackingGuard,
        props: true,
      },
      {
        path: 'dpt-go',
        name: 'DptGoProgram',
        component: () => import(
          /* webpackChunkName: "Programs" */ '../views/virtual-pt/programs/DptGoProgram.vue'
        ),
        beforeEnter: trackingGuard,
      },
      {
        path: 'download-app',
        name: 'DownloadMoveApp',
        component: () => import(/* webpackChunkName: "Programs" */ '../views/virtual-pt/programs/MoveDownloadApp.vue'),
        beforeEnter: trackingGuard,
      },
      {
        path: 'oncall-download-app',
        name: 'OnCallDownloadApp',
        component: () => import(/* webpackChunkName: "Programs" */ '../views/virtual-pt/programs/OnCallDownloadApp.vue'),
        beforeEnter: trackingGuard,
      },
      {
        path: 'academy-download-app',
        name: 'AcademyDownloadApp',
        component: () => import(/* webpackChunkName: "Programs" */ '../views/virtual-pt/programs/AcademyDownloadApp.vue'),
        beforeEnter: trackingGuard,
      },
      {
        path: 'dptgo/excluded-member',
        name: 'DptGoExcludedMember',
        component: () => import(/* webpackChunkName: "Programs" */ '../views/feedbacks/DptGoExcludedMemberFeedback.vue'),
        beforeEnter: trackingGuard,
      },
      {
        path: 'presentation',
        name: 'Wizard',
        component: () => import(/* webpackChunkName: "Programs" */ '../views/virtual-pt/programs/Wizard.vue'),
        beforeEnter: trackingGuard,
        props: true,
      },
      {
        path: 'error',
        name: 'ProgramError',
        component: () => import(/* webpackChunkName: "Programs" */ '../views/feedbacks/ErrorFeedback.vue'),
        beforeEnter: trackingGuard,
        props: true,
      },
      {
        path: 'not-eligible',
        name: 'ProgramNotEligible',
        component: () => import(/* webpackChunkName: "Programs" */ '../views/feedbacks/NotEligibleProgram.vue'),
        beforeEnter: trackingGuard,
        props: true,
      },
    ],
  },
];

const triageRoutes = [
  {
    path: '/triage/:triageUUID',
    component: () => import('../components/layout/Layout.vue'),
    beforeEnter: evaluateGuards([authGuard, triageGuard]),
    children: [
      {
        path: '',
        name: 'Helper',
        component: () => import(/* webpackChunkName: "Triage" */ '../views/triage/Helper.vue'),
        beforeEnter: trackingGuard,
      },
      {
        path: 'vaginal-anatomy-selection',
        name: 'VaginalAnatomySelectionOnTriage',
        component: () => import(/* webpackChunkName: "Triage" */ '../views/triage/VaginalAnatomy.vue'),
        beforeEnter: trackingGuard,
      },
      {
        path: 'clinical-forms',
        name: 'ClinicalFormsStepTriage',
        component: () => import(/* webpackChunkName: "Triage" */ '../views/triage/ClinicalForms.vue'),
        beforeEnter: trackingGuard,
      },
      {
        path: 'outcome',
        component: () => import('../views/triage/outcomes/OutcomeContainer.vue'),
        children: [
          {
            path: ':program/:condition',
            name: 'OutcomeStep',
            beforeEnter: trackingGuard,
            component: () => import(/* webpackChunkName: "Triage" */ '../views/triage/outcomes/OutcomeStep.vue'),
          },
        ],
      },
      {
        path: 'error',
        name: 'TriageError',
        component: () => import(/* webpackChunkName: "Triage" */ '../views/feedbacks/GenericFeedbackScreen.vue'),
        beforeEnter: trackingGuard,
        props: {
          title: 'onboarding_message_something_went_wrong',
          text: 'onboarding2_subtitle_unable_to_recover',
          buttonText: 'onboarding_go_back',
          routeName: 'Programs',
        },
      },
    ],
  },
];

const recoveryRoutes = [
  {
    path: '/v3/recovery',
    component: () => import('../components/layout/Layout.vue'),
    children: [
      {
        path: '',
        name: 'Recovery',
        beforeEnter: evaluateGuards([authGuard]),
        component: () => import(/* webpackChunkName: "Recovery" */ '../views/Recovery.vue'),
      },
      {
        name: 'RefillInformation',
        path: 'refill',
        beforeEnter: evaluateGuards([authGuard]),
        props: true,
        component: () => import(/* webpackChunkName: "Refill" */ '../views/RefillInformation.vue'),
      },
      {
        name: 'RecoveryError',
        path: 'error',
        component: () => import(/* webpackChunkName: "Recovery" */ '../views/feedbacks/RecoveryError.vue'),
        props: true,
      },
    ],
  },
];

const subscriptionCenterRoutes = [
  {
    path: '/subscriptions-center/:leadUUID/',
    name: 'SubscriptionCenter',
    component: () => import('../components/layout/Layout.vue'),
    children: [
      {
        path: '',
        name: 'SubscriptionManage',
        component: () => import(
          /* webpackChunkName: "Manage" */ '../views/subscription-center/SubscriptionCenterManage.vue'
        ),
      },
      {
        path: 'success',
        name: 'SubscriptionSuccess',
        component: () => import(
          /* webpackChunkName: "SubscriptionSuccess" */ '../views/subscription-center/SubscriptionCenterSuccess.vue'
        ),
      },
      {
        path: 'error',
        name: 'SubscriptionError',
        component: () => import(
          /* webpackChunkName: "SubscriptionError" */ '../views/subscription-center/SubscriptionCenterError.vue'
        ),
      },
      {
        path: 'process',
        name: 'SubscriptionProcess',
        component: () => import(
          /* webpackChunkName: "SubscriptionProcess" */ '../views/subscription-center/SubscriptionCenterProcess.vue'
        ),
      },
    ],
  },
];

const multimodeRoutes = [
  {
    path: '/multimode/:multimodeInviteId/enrollment',
    component: () => import('../components/layout/Layout.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'InvitationRedirect',
        props: true,
        component: () => import(/* webpackChunkName: "Multimode" */ '../views/multimode/InvitationRedirect.vue'),
      },
      {
        path: 'error',
        name: 'MultimodeError',
        component: () => import(/* webpackChunkName: "Multimode" */ '../views/feedbacks/GenericFeedbackScreen.vue'),
        props: {
          title: 'onboarding_message_something_went_wrong',
          buttonText: 'onboarding_go_back',
          routeName: 'Login',
        },
      },
    ],
  },
];

const backwardsCompatibilityRoutes = [
  {
    // Before we implemented an Account, the starting onboarding route was
    // screening/eligibility.
    path: '/c/:clientRef/screening/eligibility',
    redirect: { name: 'client' },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes: [
    ...memberAccountRoutes,
    ...clientRoutes,
    ...programsRoutes,
    ...triageRoutes,
    ...recoveryRoutes,
    ...backwardsCompatibilityRoutes,
    ...appointmentRoutes,
    ...subscriptionCenterRoutes,
    ...multimodeRoutes,
    {
      name: 'GenericError',
      path: '/generic-error',
      props: {
        title: 'onboarding_message_something_went_wrong',
        text: 'onboarding2_subtitle_unable_to_recover',
        buttonText: 'onboarding2_button_try_again',
        routeName: 'Recovery',
      },
      component: () => import(
        /* webpackChunkName: "GenericError" */ '../views/feedbacks/GenericFeedbackScreen.vue'
      ),
    },
    {
      path: '*',
      redirect: to => {
        console.info('[router] got a generic redirect when trying to go to', to.path);
        return { name: 'Login' };
      },
    },
  ],
  scrollBehavior() {
    return new Promise(resolve => {
      delayedScrollToTop(DYNAMIC_FORMS_ANIMATION_DELAY, resolve({ x: 0, y: 0 }));
    });
  },
});

router.afterEach(() => {
  const { resetPageInfo } = usePageInfo();
  resetPageInfo();
});

export default router;
