<template>
  <div
    id="app"
    :class="[
      `unit-${unitName}`,
      `theme-${currentDomain.name}`
    ]"
  >
    <router-view />
    <cookie-modal />
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { selectTheme } from '@ui-kit/themes';
import {
  authStoreTypes, i18nStoreTypes, applicationStoreTypes,
} from '@/store/types';
import sessionTimer from '@/mixins/session-timer';
import useDomainChecker from '@/composables/domainChecker';
import CookieModal from './components/CookieModal.vue';
import useFavicon from './composables/useFavicon';
import useTitle from './composables/useTitle';

export default {
  name: 'App',
  components: {
    CookieModal,
  },
  mixins: [sessionTimer],
  setup() {
    const { currentDomain } = useDomainChecker();
    useFavicon();
    useTitle();

    return {
      currentDomain,
    };
  },
  computed: {
    ...mapGetters({
      enrollmentId: 'enrolment/getEnrolmentUUID',
      unitName: applicationStoreTypes.getters.UNIT_NAME,
    }),
    ...mapState({
      deviceSource: state => state.application.deviceSource,
    }),
  },
  watch: {
    enrollmentId: {
      handler(newEnrollmentId) {
        if (window.sdLogger) {
          window.sdLogger.setLogLabels({
            client_id: this.$store.getters['client/getClientIdentifiers'].id,
            enrollment_id: newEnrollmentId,
          });
        }
      },
      immediate: true,
    },
  },
  created() {
    this.loadStoredAuthTokens();
    this.updateAppLanguage();
    this.setDeviceSource();
    selectTheme(this.currentDomain.name);
  },
  methods: {
    ...mapMutations({
      loadStoredAuthTokens: authStoreTypes.mutations.LOAD_STORED_AUTH_TOKENS,
    }),
    ...mapActions({
      updateAppLanguage: i18nStoreTypes.actions.UPDATE_APP_LANGUAGE,
      setDeviceSource: applicationStoreTypes.actions.SET_DEVICE_SOURCE,
    }),
  },
};
</script>

<style lang="scss">
html,
body,
#app {
  width: 100%;
  min-height: 100%;
  background: $background-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  @extend %font-primary;
  font-size: $base-font-size;
  color: $context-color-1;
  line-height: $line-height-base;
}

@media screen and (max-width: $max-smartphone-size) {
  .Vue-Toastification__container.top-center.wuk-toast-container {
    top: 3.5rem;
  }
}
</style>
